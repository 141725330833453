import React from 'react'
import Modal from 'react-modal'

export default class VacancyListItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalIsOpen: false,
            autoRejectIsOpen: false,
            autoRejectActive: false,
            autoRejectLanguageActive: false,
            autoRejectResidentActive: false,
            autoRejectXpRef: null,
            autoRejectXpThreshold: 0,
            autoRejectQuestions: null,
            number_of_days: 30
        }

        Modal.setAppElement('#react-company-container')
    }

    componentDidMount() {
      const {job} = this.props
      this.setState({
        autoRejectXpRef: job.reject_xp_ref,
        autoRejectXpThreshold: job.reject_xp_threshold,
        autoRejectResidentActive: job.reject_resident_active,
        autoRejectLanguageActive: job.reject_language_active
      })

      this.setState({autoRejectActive: job.auto_reject_active})
    }

    isAutoRejectActiveOnJob = () => {
      return (this.state.autoRejectXpRef && this.state.autoRejectXpRef != '') || this.state.autoRejectLanguageActive || this.state.autoRejectResidentActive
    }

    saveAutoRejectOptions = (jobId) => {
      const data = {
        vacancy: {
          reject_xp_ref: this.state.autoRejectXpRef,
          reject_xp_threshold: this.state.autoRejectXpThreshold,
          reject_resident_active: this.state.autoRejectResidentActive,
          reject_language_active: this.state.autoRejectLanguageActive
        }
      }

      fetch(`/vacancies/${jobId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
          },
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
          if(data.message != 'OK') {
            alert('FAILED')
          } else {
            this.setState({autoRejectActive: this.isAutoRejectActiveOnJob()})
            this.closeAutoReject()
          }
        })
    }

    openAutoReject = (jobId) => {
      if(this.state.autoRejectQuestions === null) {
        fetch(`/vacancies/${jobId}/questions`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
            }
          })
          .then(response => response.json())
          .then(data => {
            this.setState({autoRejectQuestions: data.questions})
            this.setState({autoRejectIsOpen: true})
          })
      } else {
        this.setState({autoRejectIsOpen: true})
      }
    }

    closeAutoReject = () => {
      this.setState({autoRejectIsOpen: false})
    }

    openModal = () => {
        this.setState({modalIsOpen: true})
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }

    onXpReferenceChange = (event) => {
      const target = event.target
      const value = target.value

      this.setState({autoRejectXpRef: value})
    }

    onInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
          [name]: value
        });
    }

    render() {
        const { job, companyType } = this.props
        const kpi_text = job.link_ad ? 'Clicks' : 'Applications'

        return (
            <div className="row vacancy-list__item">
                <div className="column primary">
                    <h4>{job.title}</h4>
                    <strong>@ {job.company_name}</strong>
                    <span>{job.regions.join(", ")}</span>
                    <span>ID: {job.id}</span>
                </div>
                <div className="column">
                    {job.publish_count > 0 && (
                        <div>
                            <strong>{job.meta.current_kpi} &nbsp;<span>{kpi_text}</span></strong>
                            <strong>{job.meta.current_views} &nbsp;<span>Views</span></strong>
                            <strong>{job.meta.total_kpi} &nbsp;<span>Total {kpi_text}</span></strong>
                            <strong>{job.meta.total_views} &nbsp;<span>Total Views</span></strong>
                        </div>
                    )}
                </div>
                <div className="column">
                    <strong><span>{job.publish_count > 0 && 'Published:'}</span> {job.meta.initial_publish_date}</strong>
                    {job.last_publish_date != job.meta.initial_publish_date && (
                      <strong><span>{job.publish_count > 0 && 'Bumped:'}</span> {job.last_publish_date}</strong>
                    )}
                    {job.publish_count > 0 && companyType != 'subscription' && (
                        <strong><span>Deadline:</span> {job.meta.deadline}</strong>
                    )}
                    {job.publish_count > 0 && job.days_left > 0 && companyType != 'subscription' && (
                        <strong><span>Days left:</span> {job.meta.days_left}</strong>
                    )}
                    {job.publish_count > 0 && companyType != 'subscription' && (
                        <strong><span>Number of days:</span> {job.meta.current_number_of_days}</strong>
                    )}
                    {job.publish_count > 1 && (
                        <strong><span>Publish count:</span> {job.publish_count}</strong>
                    )}
                    <div>
                      <em>Auto reject: {this.state.autoRejectActive ? 'ACTIVE' : 'NOT Active'}</em>
                      <br/>
                      <React.Fragment>
                        <a onClick={() => this.openAutoReject(job.id)}>{this.state.autoRejectActive ? 'Change Reject Options' : 'Activate'}</a>
                        <Modal
                            isOpen={this.state.autoRejectIsOpen}
                            onRequestClose={this.closeAutoReject}
                            contentLabel="Auto Reject Options"
                            className="Modal"
                            overlayClassName="Overlay"
                        >
                            <h3>Auto Reject Options for {job.title}</h3>
                            <div className="flex">
                              <div className="row">
                                <div className="column">
                                  <label>Require that candidate can speak swedish fluently</label>
                                  <input type="checkbox" checked={this.state.autoRejectLanguageActive} onChange={() => this.setState({autoRejectLanguageActive: !this.state.autoRejectLanguageActive})} />
                                  <label>Require that candidate is living in Sweden</label>
                                  <input type="checkbox" checked={this.state.autoRejectResidentActive} onChange={() => this.setState({autoRejectResidentActive: !this.state.autoRejectResidentActive})} />
                                </div>
                                <div className="column">
                                  <label>Pick a question to use as a filter</label>
                                  <select defaultValue={this.state.autoRejectXpRef} onChange={this.onXpReferenceChange}>
                                    <option value="">Don't use a question for auto reject</option>
                                    {this.state.autoRejectQuestions != null && this.state.autoRejectQuestions.map(q => {
                                      return (<option key={q.id} value={q.id}>{q.title}</option>)
                                    })}
                                  </select>
                                  { this.state.autoRejectXpRef != '' && this.state.autoRejectXpRef != null && (
                                    <React.Fragment>
                                    <label>If a candidate answers this or less, he/she will be rejected automatically</label>
                                    <input type="number" min="0" name="autoRejectXpThreshold" value={this.state.autoRejectXpThreshold} onChange={this.onInputChange} />
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                  <input type="submit" value="Update Auto Reject Options" onClick={() => this.saveAutoRejectOptions(job.id)} />
                              </div>
                            </div>
                        </Modal>
                      </React.Fragment>
                    </div>
                </div>
                <div className="column column__centered">
                    {this.props.activeList == 'active' && job.meta.latest_publish_id > 0 && (
                        <div>
                            { companyType != 'subscription' && (
                              <React.Fragment>
                              <a className="" onClick={this.openModal}>Set Number of Days</a>
                              <Modal
                                  isOpen={this.state.modalIsOpen}
                                  onRequestClose={this.closeModal}
                                  contentLabel="Set Number of Days"
                                  className="Modal"
                                  overlayClassName="Overlay"
                              >
                                  <h3>Set Number of Publish Days for this Vacancy</h3>
                                  <p>Remember that you can't save if deadline is before tomorrow.</p>
                                  <form action={`/vacancies/${job.id}/product_credits/${job.meta.latest_publish_id}`} method="POST">
                                      <input type="hidden" name="authenticity_token" value={document.getElementsByName('csrf-token')[0].content} />
                                      <input type="hidden" name="_method" value="PUT" />
                                      <input type="hidden" name="billing_company_id" value={this.props.companyId} />
                                      <input type="hidden" name="vacancy_id" value={job.id} />
                                      <div className="row">
                                          <div className="column">
                                              <input type="number" name="number_of_days" onChange={this.onInputChange} value={this.state.number_of_days} min="1" max="60" step="1" />
                                          </div>
                                      </div>
                                      <div className="row">
                                          <input type="submit" value="Set Number of Days" />
                                      </div>
                                  </form>
                              </Modal>
                              </React.Fragment>
                            )}

                            {job.meta.featured && (
                              <strong>🤩 PRO Active</strong>
                            )}
                            <form action={`/vacancies/${job.id}/feature`} method="POST">
                              <input type="hidden" name="authenticity_token" value={document.getElementsByName('csrf-token')[0].content} />
                              <input type="hidden" name="billing_company_id" value={this.props.companyId} />
                              <input type="hidden" name="_method" value="PUT" />
                              <div className="row">
                                <input type="submit" className="small-button" value={job.meta.featured ? 'Remove PRO' : 'Add PRO'} />
                              </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
